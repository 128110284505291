<template>
  <div>
    <el-row class="text-center pz-font-size-bs">
      <el-col
        :xl="{span: 12, offset:6}"
        :lg="{span: 12, offset:6}"
        :md="{span: 12, offset:6}"
        :sm="{span: 12, offset:6}"
        :xs="{span: 24}"
      >
        <el-col
          :span="24"
          :class="['pz-clearfix', 'border-bottom']"
        >
          <el-col
            :span="12"
            class="text-left"
          >
            {{ $t('forms_flujo.oferta_solicitud_table.amount_requested') }}
          </el-col>
          <el-col
            :span="12"
            class="text-right"
          >
            <transition name="breadcrumb">
              <b :key="myForm.form.monto_oferta">
                {{ myForm.form.monto_oferta|currency(myForm.producto.moneda_prestamo.symbol, 0) }}</b>
            </transition>
          </el-col>
        </el-col>
				
        <el-col
          :span="24"
          :class="['pz-clearfix', 'border-bottom']"
        >
          <el-col
            :span="12"
            class="text-left"
          >
            {{ $t('forms_flujo.oferta_solicitud_table.plazo') }}
          </el-col>
          <el-col
            :span="12"
            class="text-right"
          >
            <transition name="breadcrumb">
              <b :key="myForm.form.plazo">
                {{
                  $tc('forms_flujo.oferta_solicitud_table.plazo_cuotas', myForm.form.plazo, {
                    cuotas: myForm.form.plazo,
                    type: myForm.producto.term_type.name
                  })
                }}
              </b>
            </transition>
          </el-col>
        </el-col>
        <el-col
          :span="24"
          :class="['pz-clearfix', 'border-bottom']"
        >
          <el-col
            :span="12"
            class="text-left"
          >
            {{ $t('forms_flujo.oferta_solicitud_table.amount') }}
          </el-col>
          <el-col
            :span="12"
            class="text-right"
          >
            <transition name="breadcrumb">
              <b
                v-if="myForm.form.cuota_sugerida!==0"
                :key="myForm.form.cuota_sugerida"
              >
                {{ myForm.form.cuota_sugerida| currency( myForm.producto.moneda_prestamo.symbol, 0) }}
              </b>
              <b
                v-else
                :key="myForm.form.cuota_sugerida"
                class="font-weight-bold"
              > ...
              </b>
            </transition>
          </el-col>
        </el-col>
        <el-col
          :span="24"
          :class="['pz-clearfix', 'border-bottom']"
        >
          <el-col
            :span="12"
            class="text-left"
          >
            {{ $t('forms_flujo.oferta_solicitud_table.fecha_pago') }}
          </el-col>
          <el-col
            :span="12"
            class="text-right"
          >
            <transition name="breadcrumb">
              <b :key="date">
                {{ date }}
              </b>
            </transition>
          </el-col>
        </el-col>
      </el-col>
    </el-row>
    <el-row
      class="pz-large-margin"
    >
      <el-row
        type="flex"
        justify="center"
        class="pz-large-margin"
      >
        <span
          v-if="myForm.producto.americana"
          class="pz-font-size-sm tasa-class text-center"
        >
          {{ $t('forms_flujo.pz_simulators_template.americana') }}
        </span>
      </el-row>
      <el-row
              type="flex"
              class="text-center"
      >
          <span
                  class=" pz-font-size-sm"
          >{{ $t('forms_flujo.oferta_solicitud_table.text_adicional') }}
          </span>
      </el-row>
      <transition name="breadcrumb">
        <el-row
          :key="myForm.form.tasa"
          type="flex"
          justify="center"
        >
          <span
            v-if="myForm.form.tasa && $t('forms_flujo.oferta_solicitud_table.tasa')!='' "
            class="tasa-class pz-font-size-sm"
          >{{ text_tasa  }}:
            <b> {{ myForm.form.tasa|currency( ' ', myForm.form.cant_decimales) }}% </b>
          </span>
          <span
            v-else
            class="tasa-class pz-font-size-sm"
          >
            <b> &#8203;</b>
          </span>
        </el-row>
      </transition>

    </el-row>
  </div>
</template>

<script>
import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
import moment from "moment";

export default {
    name: "PzOfertaSolicitudTable",
    mixins: [PzOfertaSolicitudDefault],
    data() {
        return {
            date: '',
            text_tasa: '',
        }
    },

    mounted: function () {
        console.log(process.env.VUE_APP_CLIENT)
        if(process.env.VUE_APP_CLIENT=='impacta'){
            this.text_tasa = this.$t('forms_flujo.oferta_solicitud_table.tasa_semanal')
        }else{
            this.text_tasa = this.$t('forms_flujo.oferta_solicitud_table.tasa')
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        getDate() {
            {
                this.date = moment().add(this.myForm.producto.term_type.cant_days, 'days').format(this.$t('date_format'));
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.tasa-class {
  transform: /*translateX(-60px)*/
         translateY(15px);
}

</style>